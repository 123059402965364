'use client';

import { faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';

import { EventCardBadge, EventCardBadgeType, EventCardProps } from '@/tournament/components/EventCard';

export const MiniEventBadge = ({ children, type }: { children: React.ReactNode; type: EventCardBadgeType }) => {
	return (
		<EventCardBadge className="w-full rounded-b-none rounded-t-md" type={type} size="sm">
			{children}
		</EventCardBadge>
	);
};

export const PPAEventCard = ({
	dateFrom,
	dateTo,
	// isCanceled,
	// isCostPerEvent,
	// isFree,
	// isRegistrationClosed,
	// isTournamentCompleted,
	logo,
	location,
	// lat,
	// lng,
	slug,
	registrationCount,
	status,
	title
}: EventCardProps) => {
	const tourneyFromDate = new Date(dateFrom);
	const tourneyToDate = new Date(dateTo);

	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

	const formattedFromDate = new Intl.DateTimeFormat('en-US', options).format(tourneyFromDate);
	const formattedToDate = new Intl.DateTimeFormat('en-US', options).format(tourneyToDate);

	const getImageToDisplay = useMemo(() => {
		if (logo.endsWith('placeholder/pickleball.png')) {
			return `${process.env.NEXT_PUBLIC_CDN_URL}/static/images/pickleball.png`;
		}

		return logo;
	}, [logo]);

	return (
		<a href={`${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${slug}`} className="flex">
			<div className=" flex w-[180px] select-none">
				<div className="flex w-full cursor-pointer flex-col rounded-md border border-blue-700 bg-blue-900">
					<div className="relative flex h-28 items-center justify-center overflow-hidden rounded-t-md bg-white">
						<img src={getImageToDisplay} alt={title} className="block h-24 w-full rounded-t-md object-contain" />
					</div>
					<div className="pointer-events-none relative rounded-t-md bg-white">
						{status === 'price' && (
							<div className="absolute -bottom-1 right-2 flex size-6 items-center justify-center rounded-full bg-success-500">
								<FontAwesomeIcon icon={faMoneyBill} className="text-[12px] text-white" />
							</div>
						)}
					</div>
					<div className="flex flex-1 flex-col p-2">
						<p className="mb-1 text-xs text-blue-200">{`${formattedFromDate} - ${formattedToDate}`}</p>
						<p className="line-clamp-2 whitespace-pre-wrap text-sm font-bold leading-4 text-white">{title}</p>
						<p className="font-body mt-auto flex items-center pt-2 text-xs text-white">{location}</p>
					</div>
					<div className="mt-auto flex flex-wrap items-center border-t  border-white px-2 text-sm text-gray-700 md:flex-row">
						<div className="my-0">
							<span className="text-xs text-white">{`${registrationCount} Players`}</span>
						</div>
					</div>
				</div>
			</div>
		</a>
	);
};
