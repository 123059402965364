'use client';

import clsx from 'clsx';
import React, { useMemo } from 'react';

import getPBracketsCDNURL from '@/utils/getPBracketsCDNURL';
import getCDNURL from '@/utils/getPickleballCDNURL';

export interface EventCardProps {
	id: string;
	dateFrom: string;
	dateTo: string;
	isCanceled: boolean;
	isCostPerEvent: boolean;
	isFree: boolean;
	currency: string;
	location: string;
	isRegistrationClosed: boolean;
	isTournamentCompleted: boolean;
	logo: string;
	price: number;
	lat: number;
	lng: number;
	registrationCount: number;
	slug: string;
	status: string;
	title: string;
}

export const EventCard = ({
	id,
	dateFrom,
	dateTo,
	// isCanceled,
	// isCostPerEvent,
	// isFree,
	// isRegistrationClosed,
	// isTournamentCompleted,
	logo,
	currency,
	location,
	price,
	// lat,
	// lng,
	slug,
	registrationCount,
	status,
	title
}: EventCardProps) => {
	const tourneyFromDate = new Date(dateFrom);
	const tourneyToDate = new Date(dateTo);

	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

	const formattedFromDate = new Intl.DateTimeFormat('en-US', options).format(tourneyFromDate);
	const formattedToDate = new Intl.DateTimeFormat('en-US', options).format(tourneyToDate);

	const getImageToDisplay = useMemo(() => {
		if (logo.endsWith('placeholder/pickleball.png')) {
			return getCDNURL('https://images.pickleball.com/pickleball-app/static/images/pickleball.png', 283, 192);
		}

		return getPBracketsCDNURL(logo, 283, 192);
	}, [logo]);
	return (
		<a href={`${[process.env.NEXT_PUBLIC_PT_URL]}/tournaments/${slug ? slug : id}`} className="contents">
			<div
				className="flex cursor-pointer flex-col rounded-lg border bg-white"
				style={{
					boxShadow: 'rgba(0, 0, 0, 0.1) 0 2px 3px 0px'
				}}
			>
				<div className="md:h-70 relative !h-48">
					<img src={getImageToDisplay} alt={title} className="block h-48 w-full object-contain" />
				</div>
				<div className="flex flex-1 flex-col justify-between gap-1.5 p-2">
					{/* <div>{`${lat} - ${lng}`}</div> */}
					<div className="line-clamp-1 block truncate text-sm font-medium text-gray-700" title={title}>
						{title}
					</div>
					{location && <div className="text-sm font-medium text-gray-500"> {location} </div>}
					{(formattedFromDate || formattedToDate) && (
						<div className="text-sm text-gray-500">{`${formattedFromDate} - ${formattedToDate}`}</div>
					)}
					{(status || registrationCount) && (
						<div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-1">
							{status === 'canceled' && <EventCardBadge type="danger">Canceled</EventCardBadge>}
							{status === 'completed' && <EventCardBadge type="default">Completed</EventCardBadge>}
							{status === 'registration-closed' && <EventCardBadge type="default">Reg. Closed</EventCardBadge>}
							{status === 'price' && (
								<EventCardBadge type="success">
									{`${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol' }).format(price)}`}
								</EventCardBadge>
							)}
							{status === 'cost-per-event' && <EventCardBadge type="info">Cost Per Event</EventCardBadge>}
							{status === 'free' && <EventCardBadge type="success">Free</EventCardBadge>}
							<div className="text-xs font-semibold text-gray-700">{registrationCount} registrations</div>
						</div>
					)}
				</div>
			</div>
		</a>
	);
};

export type EventCardBadgeType = 'info' | 'danger' | 'default' | 'success';

export const EventCardBadge = ({
	children,
	className,
	type,
	size = 'md'
}: {
	children: React.ReactNode;
	className?: string;
	type: EventCardBadgeType;
	size?: 'sm' | 'md';
}) => {
	const classnames = clsx(
		'',
		{
			'tracking-widertext-white bg-danger-500 px-2 py-1 text-center text-xs font-semibold text-white rounded-md': size === 'md',
			'tracking-widertext-white bg-primary-500 px-2 py-1 text-center text-xs font-semibold text-white rounded-md': size === 'sm',
			'tracking-widertext-white bg-success-700 px-2 py-1 text-center text-xs font-semibold text-white rounded-md': type === 'default',
			'tracking-widertext-white bg-[#79bfd5] px-2 py-1 text-center text-xs font-semibold text-white rounded-md': type === 'info',
			'tracking-widertext-white bg-success-800 px-2 py-1 text-center text-xs font-semibold text-white rounded-md': type === 'success',
			'tracking-widertext-primary-500 bg-gray-200 !text-[rgba(0,0,0,0.6)] px-2 py-1 text-center text-xs font-semibold rounded-md':
				type === 'info' || type === 'danger'
			// 'tracking-widertext-primary-500  text-white px-2 py-1 text-center text-xs font-semibold': type === 'danger'
		},
		className
	);

	return <div className={classnames}>{children}</div>;
};
